import * as React from 'react'
import { Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import moment from 'moment'
import 'moment/locale/de'  // without this line it didn't work
import { useLocation } from '@reach/router';

import translate from '../utils/translator'

// A summary of the Blog Post
const EventSummary = ({ event, id }) => {
  // console.log('event: ', event);
  if (!event) return (<></>);
  const {data, uid, url} = event
  const detail = data.body.find(item => {
    return item.slice_type === 'event_info'
  })
  if (!detail) return null
  // console.log('rest detail: ', detail)
  const {items, primary} = detail
  const firstImgItem = items[0]
  let image = ""
  if (firstImgItem && firstImgItem.image && firstImgItem.image.url) {
    image = firstImgItem.image.url
  }
  
  moment.locale('de')
  const startDate = moment(event.data.start_time).format("DD MMMM YYYY")
  const startTime = moment(event.data.start_time).format("HH:mm")
  const finishDate = moment(event.data.finish_time).format("DD MMMM YYYY")

  // const now = moment().utc()
  // const finishUtc = moment(data.finish_time).tz("Europe/Berlin")
  // const isOver = now.isAfter(finishUtc)
  const isOver = event.data.isOver;

  let showType = true
  if (useLocation().pathname == "/events") {
    showType = false
  }
  // todo: https://stackoverflow.com/questions/42561137/link-cannot-appear-as-a-descendant-of-a-link
  return (
    <div className="col-lg-4">
			<div className={"overflow-hidden h-100 d-flex flex-column " + (isOver && "opacity-50")}>
        <a href={url} className="bg-dark position-relative overflow-hidden" style={{height:"200px"}}>
          {image && <img className="image-cover" src={image} alt={event.data.title.text + " Titelfoto"}/>}
          {/*showType && <a href={"/events"} className="btn btn-sm btn-primary position-absolute fs-075" style={{bottom:"16px",left:"16px"}}>{translate('btn_events')}</a>*/}
        </a>
				<div className="d-flex flex-fill flex-column justify-content-between">
          <div className="pt-3 flex-fill">
            <div className="d-flex fs-0875 fw-600">
					    <a href={url} className="text-decoration-none d-flex align-items-center text-dark">
                <img className="me-1 details-icon" height="16" width="16" src={`/icons/event-dates.svg`} alt={"Veranstaltungsdaten Icon"}/>
                {startDate}{" - "}{finishDate}
              </a>
            </div>
            <a className="text-decoration-none" href={url}>
              <h6 className="mt-2 mb-0">{(isOver ? "ABGELAUFEN: " : "") + event.data.title.text || 'Untitled'}</h6>
            </a>
          </div>
				</div>
			</div>
		</div>
  )
}

export const Events = ({ docs }) => {
  if (!docs) return null

  const events = docs.events

  return (
    <div className="mt-4 row g-4">
      {events.map((event) => (
        <EventSummary event={event} key={event.uid} />
      ))}
      {/* <Pagination pageInfo={docs.pageInfo} /> */}
    </div>
  )
}
