import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TwoTitles = ({ slice }) => {
  const {link_1, pretitle_1, title_1, aftertitle_1, link_2, pretitle_2, title_2, aftertitle_2} = slice.primary

  return (
    <div className="">
        <div className="me-0 row gy-0">
          <a href={link_1.url} className="bg-primary py-80px col-12 col-md-6 text-center text-uppercase text-decoration-none">
            <div className="h-100 d-flex flex-column justify-content-center">
              <h3 className="text-white" style={{letterSpacing: "4px"}}>{pretitle_1}</h3>
              <h2 className="text-white mt-4" style={{letterSpacing: "4px"}}>{title_1}</h2>
              {aftertitle_1 && <h4 className="text-white mt-4" style={{letterSpacing: "4px"}}>{aftertitle_1}</h4>}
            </div>
          </a>
          <a href={link_2.url} className="py-80px col-12 col-md-6 text-center text-uppercase text-decoration-none">
            <div className="h-100 d-flex flex-column justify-content-center">
              <h3 className="text-white" style={{letterSpacing: "4px"}}>{pretitle_2}</h3>
              <h2 className="text-primary mt-4" style={{letterSpacing: "4px"}}>{title_2}</h2>
              {aftertitle_2 && <h4 className="text-white mt-4" style={{letterSpacing: "4px"}}>{aftertitle_2}</h4>}
            </div>
          </a>
        </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTwoTitles on PrismicHomepageDataBodyTwoTitles {
    primary {
      link_1 {
        url
      }
      pretitle_1
      title_1
      aftertitle_1
      link_2 {
        url
      }
      pretitle_2
      title_2
      aftertitle_2
    }
  }
`
