import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import {Events} from '../components/Events'
import translate from '../utils/translator'

import { loadEvents } from '../utils/utils';

export const MainEvents = ({ slice, context }) => {
  const [events,setEvents] = useState([])

  async function loadData() {
    const filtered = await loadEvents(3);
    setEvents(filtered);
  }

  React.useEffect(() => {
    loadData();
  }, [])
  
  const docs = {
    events: events,
  }
  
  return (
    <div className={"py-4 bg-light"}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{slice.primary.title1.text}</h2>
        <h5 className="text-center">{slice.primary.desc1.text}</h5>
        <Events docs={docs} />
        <div className="mt-5 text-center">
          <a href="/events" className="btn btn-primary">{translate('btn_show_all_events')}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMainEvents on PrismicHomepageDataBodyMainEvents {
    id
    slice_type
    primary {
      title1 {
        text
      }
      desc1 {
        text
      }
    }
  }
`
