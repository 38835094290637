import { MainCover } from './MainCover'
import { Menu } from './Menu'
import { ReservationForm } from './ReservationForm'
import { TitleAndText } from './TitleAndText'
import { TwoTitles } from './TwoTitles'
import { ImgGallery } from './ImgGallery'
import { Title } from './Title'
import { MainEvents } from './MainEvents'
import { EventInfo } from './EventInfo'

export const components = {
  event_info: EventInfo,
  main_cover: MainCover,
  menu: Menu,
  main_events: MainEvents,
  reservation_form: ReservationForm,
  title_and_text: TitleAndText,
  two_titles: TwoTitles,
  img_gallery: ImgGallery,
  title: Title,
}
