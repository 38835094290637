import * as React from 'react'
import { graphql } from 'gatsby'
import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

export const ImgGallery = ({ slice }) => {
  const galleryImages = slice.items.map((items, index) => {
    const imgUrl = items.img.url;
    return (
      <Carousel.Item key={index} interval={2000} className="ar16-9">
        <Item
          key={index}
          original={imgUrl}
          thumbnail={imgUrl}
          width={2400}
          height={2400}
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src={imgUrl}
              alt={""}
              className="image-cover"
            />
          )}
        </Item>
      </Carousel.Item>
    );
  });

  return (
    <div className="container py-80px">
      <h2 className="text-center text-primary mb-4">{slice.primary.title}</h2>
      <Gallery>
        <Carousel controls={(galleryImages.length > 1) ? true : false} indicators={(galleryImages.length > 1) ? true : false}>
          {galleryImages}
        </Carousel>
      </Gallery>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImgGallery on PrismicHomepageDataBodyImgGallery {
    primary {
      title
    }
    items {
      img {
        url
      }
    }
  }
`
