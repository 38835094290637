import * as React from 'react'
import { graphql } from 'gatsby'
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import de_DE from 'antd/lib/date-picker/locale/de_DE';
import { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export const ReservationForm = ({ slice }) => {
  const {title, bg_img} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const rdate = useRef();
  const rtime = useRef();

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onDateChange = (date,dateString) => {
    console.log('onDateChange: ', dateString);
    rdate.current = dateString;
  }
  const onTimeChange = (time, timeString) => {
    console.log('onTimeChange: ', timeString);
    rtime.current = timeString;
  }

  const onSubmit = async (data) => {
    if (rdate.current) data.date = rdate.current;
    if (rtime.current) data.time = rtime.current;
    console.log('data: ', data);
    const url = "https://hook.eu1.make.com/a9k1212d9189g9q6jyxd4clp9pherk2q";
    const r = await callHook({url, uid:'corners-sifi-reservation', params:data});
    console.log(r);
    // if (r && r.success === true) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    // setValue("full", "");
    setSend(true);
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current <= moment().add(-1, 'days');
  };

  const disabledHours = () => {
    var hours = [];
    for(var i =0; i < moment().hour(); i++){
        hours.push(i);
    }
    return hours;
  }

  const disabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
  }

  if (isSend) {
    return (
      <div className={"flex-fill py-80px"} style={{background: "url(" + bg_img.url + ")",backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
		    <div className={"container"}>
          <h2 className="text-center text-primary">{title}</h2>
          <div className="mt-5 bg-dark p-5 text-center text-white">
            <h3>Danke!</h3>
            <p>Wir werden Sie in Kürze kontaktieren.</p>
            <div className="col-auto">
              <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{"TISCH RESERVIEREN"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
  <div className={"flex-fill py-80px"} style={{background: "url(" + bg_img.url + ")",backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
		<div className={"container"}>
      {title && <h2 className="text-center text-primary mb-5">{title}</h2>}
      <div className="bg-dark p-5">
        <form id="corners-sifi-reservation" className="text-center" onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-4">
            <div className="col-12 col-md-6 col-lg-4">
              <input
                id="reservation-form-persons-num"
                placeholder="Personenzahl"
                className={'form-control'}
                {...register('persons', {required: false})}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <DatePicker
                className="form-select"
                disabledDate={disabledDate}
                locale={de_DE}
                onChange={onDateChange}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <TimePicker
                className="form-select"
                format={"HH:mm"}
                minuteStep={15}
                disabledHours={() => [0,1,2,3,4,5,6,7,8,9,10]}
                locale={de_DE}
                onChange={onTimeChange}
                changeOnBlur={true}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <input
                id="reservation-form-name"
                placeholder="Vor- und Zuname"
                className={'form-control'}
                {...register('name', {required: false})}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <input
                id="reservation-form-email"
                type="email"
                placeholder="E-Mail"
                className={'form-control'}
                {...register('email', {required: false})}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <input
                id="reservation-form-phone"
                type="tel"
                placeholder="Telefonnummer"
                className={'form-control'}
                required
                {...register('phone', {required: true})}
              />
              <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
            </div>
            <div className="col-12">
              <textarea
                id="reservation-form-message"
                placeholder="Nachricht"
                className={'form-control'}
                {...register('text', {required: false})}
              />
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-primary mt-4" type="submit">ANFRAGE ABSENDEN</button>
          </div>
        </form>
      </div>
		</div>
	</div>
  )
}

export const query = graphql`
  fragment PageDataBodyReservationForm on PrismicPageDataBodyReservationForm {
    primary {
      title
      bg_img {
        url
      }
    }
  }
`
