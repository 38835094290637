import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Title = ({ slice }) => {
  const {title, text} = slice.primary

  return (
    <div className="container mt-5 mb-4">
      <h2 className="text-center text-primary h2-size">{title}</h2>
    </div>
  )
}

export const query = graphql`
  fragment MenuDataBodyTitle on PrismicMenuDataBodyTitle {
    primary {
      title
    }
  }
`
