import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TitleAndText = ({ slice }) => {
  const {title, text, white_bg} = slice.primary

  return (
    <div className={"py-80px" + " " + (white_bg ? "bg-white text-dark" : "bg-dark text-white")}>
      <div className="container">
        {title && <h2 className="text-center text-primary mb-4">{title}</h2>}
        <div className="">
          <PrismicRichText field={text.richText} />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTitleAndText on PrismicHomepageDataBodyTitleAndText {
    primary {
      white_bg
      title
      text {
        richText
      }
    }
  }
  fragment PageDataBodyTitleAndText on PrismicPageDataBodyTitleAndText {
    primary {
      white_bg
      title
      text {
        richText
      }
    }
  }
  fragment MenuDataBodyTitleAndText on PrismicMenuDataBodyTitleAndText {
    primary {
      white_bg
      title
      text {
        richText
      }
    }
  }
`
