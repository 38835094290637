import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment'
import 'moment/locale/de'  // without this line it didn't work

import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import translate from '../utils/translator'

export const EventInfo = ({ slice, context }) => {
  console.log('EventInfo slice: ', slice)
  console.log('EventInfo context: ', context)
  const cntx = context
  const {title, start_time, finish_time, restLink} = cntx
  console.log('Link: ', restLink)
  const {id, uid, type, link_type, document} = restLink
  console.log('Document: ', document)
  const _restLink = `/restaurant/${uid}`
  const restDetail = document?.data?.body?.find(item => item.slice_type === 'restaurant_detail')
  console.log('Detail: ', restDetail)
  const restName = restDetail?.primary?.rest_name
  console.log('Restaraunt name: ', restName)
  const restLogo = restDetail?.primary?.logo.url

  const {description, email, phone, address, coordinates, facebook_url, instagram_url, twitter_url, youtube_url} = slice.primary

  const images = slice.items || []
  console.log('images: ', images)
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  moment.locale('de')
  const start = moment(start_time).format("DD MMMM YYYY HH:mm")
  const finish = moment(finish_time).format("DD MMMM YYYY HH:mm")

  const now = moment().utc()
  const finishUtc = moment(finish_time).tz("Europe/Berlin")
  const isOver = now.isAfter(finishUtc)

  console.log("isOver ", isOver)
  
  const galleryImages = images.map((items, index) => {
    const imgUrl = items.image.url;
    return (
      <Carousel.Item key={index} interval={2000} className="ar16-9">
          <Item
            key={index}
            original={imgUrl}
            thumbnail={imgUrl}
            width={2400}
            height={2400}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt={"Veranstaltungsfoto " + (index+1)}
                className="image-cover"
              />
            )}
          </Item>
      </Carousel.Item>
    );
  });

  const MainInfo = () => (
    <div className="p-4">
      {isOver &&
      <div className="alert alert-danger" role="alert">{translate('event_in_the_past')}</div>
      }
      {/*<h6 className="">{translate('info_about_event')}</h6>*/}
      <div className="mt-3 fw-600 fs-0875">       
        {/*<><div><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/time-left-w.svg`}/>00 Tage 00:00:00</div><hr/></>*/}
        {start_time && 
          <><div><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/event-start-w.svg`} alt={"Startzeit der Veranstaltung Icon"}/>{start}</div><hr/></>
        }
        {finish_time && 
          <><div><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/event-end-w.svg`} alt={"Endzeit der Veranstaltung Icon"}/>{finish}</div><hr/></>
        }
        {phone && 
          <><a className="text-decoration-none text-body" href={"tel:" + phone}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/phone-w.svg`} alt={"Veranstaltungs Telefon Icon"}/>{phone}</a><hr/></>
        }
        {email && 
          <><a className="text-decoration-none text-body"href={"mailto:" + email}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/email-w.svg`} alt={"Veranstaltungs Email Icon"}/>{email}</a><hr/></>
        }
        <div className="mt-2 row g-2">
          {facebook_url.url && 
          <div className="col-auto"><a href={facebook_url.url} type="button" className="btn btn-white btn-social"><img className="details-icon" height="24" width="24" src={`/icons/facebook-w.svg`} alt={"Facebook Icon"}/></a></div>
          }
          {instagram_url.url && 
          <div className="col-auto"><a href={instagram_url.url} type="button" className="btn btn-white btn-social"><img className="details-icon" height="24" width="24" src={`/icons/instagram-w.svg`} alt={"Instagram Icon"}/></a></div>
          }
          {twitter_url.url && 
          <div className="col-auto"><a href={twitter_url.url} type="button" className="btn btn-white btn-social"><img className="details-icon" height="24" width="24" src={`/icons/twitter-w.svg`} alt={"Twitter Icon"}/></a></div>
          }
          {youtube_url.url && 
          <div className="col-auto"><a href={youtube_url.url} type="button" className="btn btn-white btn-social"><img className="details-icon" height="24" width="24" src={`/icons/youtube-w.svg`} alt={"YouTube Icon"}/></a></div>
          }
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="container py-80px">
        <h1 className="h2 text-center text-primary mb-5">{(isOver ? "ABGELAUFEN: " : "") + title.text}</h1>
        <div className="row">
          <div className="col-lg-8">
            <div className="rounded-3 bg-light d-block d-lg-none">
              <MainInfo/>
            </div>
            {isImages && (
              <div className="bg-light ar16-9 mt-4 mt-lg-0" style={{cursor:"pointer"}}>
                <Gallery>
                  <Carousel controls={(galleryImages.length > 1) ? true : false} indicators={(galleryImages.length > 1) ? true : false}>
                    {galleryImages}
                  </Carousel>
                </Gallery>
              </div>
            )}	
            <div className="mt-4 lh-175 text-white">
              <PrismicRichText field={description.richText} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sticky-top" style={{top:"24px"}}>
              <div className="bg-secondary text-white d-none d-lg-block">
                <MainInfo/>
              </div>
              {(address || coordinates) &&
                <div className="bg-secondary text-white mt-4 p-4">
                  <h6>{translate('location')}</h6>
                  {address && 
                    <a className="text-decoration-none d-flex align-items-center" href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude   + "," + coordinates.longitude) : "javascript:void(0);"} target="_blank"><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location-w.svg`} alt="Veranstaltungsort Icon"/> {address}</a>
                  }
                  {coordinates &&
                    <div className="mt-2">
                      <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
                        <GoogleMap mapContainerStyle={{width:"100%",height:"400px"}} center={{lat:coordinates.latitude,lng:coordinates.longitude}} zoom={17} options={    {fullscreenControl:false,mapTypeControl:false,streetViewControl:false,styles:[{"featureType":"poi","stylers":[{"visibility":"off"}]}]}}>
                          <Marker
                            position={{
                              lat: coordinates.latitude,
                              lng: coordinates.longitude
                            }}                     
                          >
                          </Marker>
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment EventDataBodyEventInfo on PrismicSifiEventDataBodyEventInfo {
    primary {
      description {
        richText
        text
      }
      phone
      email
      address
      coordinates {
        latitude
        longitude
      }
      facebook_url {
        url
      }
      instagram_url {
        url
      }
      twitter_url {
        url
      }
      youtube_url {
        url
      }
    }
    items {
      image {
        url
      }
    }
  }
`
