import * as React from 'react'
import { Link, graphql } from 'gatsby'

export const Footer = ({ footer }) => {

  const footerLinks = footer
    ? footer.footer_links.map((footerLink, index) => (
      <a key={index} className="col-12 col-lg-6 nav-link h4 mt-0 mb-3 active" aria-current="page" href={footerLink.link.url}>{footerLink.label}</a>
      ))
    : null


  return (
    <footer id="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-4 text-center text-md-start">
            <h6 className="text-uppercase lh-lg">
              Wettbachstraße 2<br/>
              71063 Sindelfingen<br/>
              Tel.: <a href="tel:+4970316770400" className=" text-decoration-none">+4970316770400</a>
            </h6>
            <div className="d-flex align-items-end justify-content-center justify-content-md-start mb-3 mb-md-0">
              <h6 className="m-0">Follow us:</h6>
              <a className="ms-3" href="https://www.instagram.com/corners_sindelfingen/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/></svg></a>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center text-lg-start">
              <div className="row m-0 my-4 my-md-0 g-3">
                {footerLinks}
              </div>
          </div>
          <div className="col-12 col-md-4 text-center text-md-end">
            <h6 className="text-uppercase lh-lg">
              MO-DO:&emsp;&emsp;16:00 – 23:00<br/>
              FR:&emsp;&emsp;16:00 – 23:30<br/>
              SA:&emsp;&emsp;12:00 – 23:30<br/>
              SO:&emsp;&emsp;12:00 – 22:00
            </h6>
          </div>
        </div>       
        <hr className="my-5"/>
        <div className="row gy-5">
          <a href="http://waldbiergarten-bb.de/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Waldbiergarten-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
          <a href="http://wiesengarten.online/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Wiesengarten-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
          <a href="https://parkrestaurant-sindelfingen.de/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Parkrestaurant-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
        </div>
        <hr className="my-5"/>
        <div className="text-center">
          <h6>Design & Umsetzung <a href="https://city-eats.de/" target="_blank" className="text-primary text-decoration-none">City-Eats.de</a></h6>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          url
        }
      }
    }
  }
`