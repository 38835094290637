//
const trans = {
  en: {},
  de: {
    /*events and rests info*/
    event_in_the_past: "Veranstaltung liegt in der Vergangenheit",
    info_about_event: "Informationen zur Veranstaltung",
    info_about_rest: "Infos zum Restaurant",
    route: "Routenplaner",
    location: "Standort",
    events: "Aktionen",
    videoblogs: "Videoblogs",
    menu: "Menü",
    description: "Beschreibung",
    /*schedule*/
    schedule_closed: "Geschlossen",
    schedule_open: "Geöffnet",
    /*buttons*/
    btn_order_delivery: "Zur Abholung Bestellen",
    btn_book_table: "Tisch Reservieren",
    btn_weekly_menu: "Wochenkarte",
    btn_show_all: "Alle anzeigen",
    btn_show_all_events: "Alle Aktionen anzeigen",
    btn_watch_all_videos: "Alle Videos ansehen",
    btn_events: "Aktionen und Events",
    btn_videoblog: "Videoblog",
    btn_view_details: "Details ansehen",
    /*others*/
    area: "Größe",
    persons: "Personen",
    back: "Zurück",
    stories_recommendations: "Die Sifi-Eats Empfehlungen",
  },
}
const translate = (text, lang) => {
  const l = lang || 'de'
  return trans[l][text] || text
}
export default translate