import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

export const MainCover = ({ slice }) => {
  const {pretitle, title, aftertitle, cover_img} = slice.primary
  return (
    <div id="top" className="main-cover-bg" style={{backgroundImage: "url(" + cover_img.url + ")"}}>
      <div className="main-cover-blackout h-100">
        <div className="container">
          <div className="text-center text-uppercase main-title-shadow">
            <h2 className="text-primary main-pretitle">{pretitle}</h2>
            <h1 className="text-white main-title my-4">
              <span className="">{"-"}</span>
                {title}
              <span className="">{"-"}</span>
            </h1>
            <h2 className="text-primary  main-aftertitle">{aftertitle}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMainCover on PrismicHomepageDataBodyMainCover {
    primary {
      pretitle
      title
      aftertitle
      cover_img {
        url
      }
    }
  }
`
